import React from "react";
import logo from "./../../Assets/Images/LogoSendero1.png";

const Logo = () => {
  return (
    <div className="text-center mb-4">
      <img src={logo} alt="" className="img-fluid logo img_logo_sacra"/>
    </div>
  );
};
export default Logo;
