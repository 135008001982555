import React, { Component } from 'react';

import Session from '../../service/Session';
import Logo from './Logo/Logo';
import { RS } from './RS';
import "../Assets/css/new-style.scss";

import { Container, Row, Col, Spinner } from 'reactstrap';
import * as Api from "../api";
import { HashLink as Link } from 'react-router-hash-link';
import casa from "../Assets/Images/casa.svg";

class PagoRapidoKhipuError extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedCuota: {},
            collapse: 1,
            items: {},
            nombre: Session.getInfoUserPagoRapido().Pusuario,
            rut: Session.getInfoUserPagoRapido().Prut,
            token_ws: 0,
            url: '',
            disabledPagar: true,
            show: true,
            cuotas: {
                0: {},
                1: {},
                2: {}
            },
            montoTotal: 0,
            bloqueo: [],
            checkDisabled: [],
            paymentType: 1,
        }
        this.getDetalle = this.getDetalle.bind(this);
    }
    componentDidMount() {
        this.setState({ show: true });
        this.getDetalle();
    }
    async getDetalle() {
        const { location } = this.props;
        const { search } = location;
        const buyOrder = new URLSearchParams(search).get('buy_order');
        let data = {};
        data.buyOrder = buyOrder;
        let response = await Api.getDetailtsPayment(data);
        this.setState({ detalle: response.detalle, pago: response.pago, buyOrder: buyOrder, show: false });
    }
    render() {
        const { nombre } = this.state;
        const { detalle, pago, buyOrder, show } = this.state;
        return (
            <>
                <br />
                <div className="container" id="dashboard" style={{maxWidth: '1140px'}}>
                    <div className="content-contratos text-center">
                        <div className="row row-content-cabecera">
                            <div className="col-12 col-md-4 center-mobile">
                                <Logo />
                            </div>
                            <div className="col-12 col-md-8 text-right center-mobile">
                                <RS />
                            </div>
                        </div>
                        <div className="row row-content-cabecera">
                            <div className="col-12 col-md-6 text-left center-mobile">
                                <h4>Gracias <strong>{nombre}</strong></h4>
                            </div>
                            <div className="col-12 col-md-6 text-right center-mobile">
                                <a href='https://www.sacramental.cl/' target="_blank" rel="noopenr noreferrer">
                                    <img src={casa} /> <strong>Ir a Sacramental</strong>
                                </a>
                            </div>
                        </div>
                        <p>
                            <>
                                {show ? (
                                    <div className="text-center spinner">
                                        <Spinner style={{ width: '5rem', height: '5rem' }} />
                                    </div>
                                ) : (
                                    detalle.length > 0 && pago.length > 0 ? (
                                        <Container>
                                        <br />
                                             <Row className='detalle'>
                                                <Col>
                                                    <div className="text-title-detalle text-center">
                                                        <h2>TRANSACCIÓN ERRÓNEA</h2>
                                                        <br></br>  
                                                        <div className="text-center">Estimado (a) {Session.getInfoUserPagoRapido().Pusuario}, hemos recibido su solicitud de pago online a través de Khipu, asociada a la orden de compra {pago[0].buyorder}.
    La cual ha sido cancelada y/o anulada.</div>
                                                    </div>
                                                    <div>
                                                        <p className="mis-datos"><strong>Mis Datos</strong></p>
                                                    </div>
                                                    <div className="back-detalle">
                                                        <table width="100%">
                                                            <tbody>
                                                                <tr>
                                                                    <th>Nombre Cliente : </th>
                                                                    <td>{Session.getInfoUserPagoRapido().Pusuario}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Rut Cliente : </th>
                                                                    <td>{Session.getInfoUserPagoRapido().Prut}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Fecha : </th>
                                                                    <td>{pago[0].fecha}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Hora Transacción : </th>
                                                                    <td>{pago[0].hora}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>N° Transacción : </th>
                                                                    <td>{pago[0].buyorder}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Forma de Pago : </th>
                                                                    <td>Khipu</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <table className="table-detalle">
                                                        <thead>
                                                            <tr>
                                                                <th>N° Contrato</th>
                                                                <th>Tipo</th>
                                                                <th>Cuota</th>
                                                                <th>Monto</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {detalle.map((item, index) => (
                                                                item.monto_cuota > 0 ? (
                                                                    <tr key={index}>
                                                                        <td>{item.numero_contrato}</td>
                                                                        <td>{item.concepto_recaudacion}</td>
                                                                        <td>{item.numero_cuota}</td>
                                                                        <td>${new Intl.NumberFormat().format(item.monto_cuota)}</td>
                                                                    </tr>
                                                                ) : null
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </Col><br /><br />
                                            </Row>
                                            <Row><Col><p>Recuerde que también puede ingresar en www.sacramental.cl Mi Sacramental y acceder para actualizar sus datos, pagar sus cuotas, mantenciones y mucho más.</p></Col></Row>
                                            <Row>
                                                <Col>
                                                    <br />
                                                    <Link to={'/'} className="btn btn-verde button btn btn-secondary">Volver</Link>
                                                </Col>
                                            </Row>
                                        </Container>
                                    ) : (
                                        <Container>
                                            <Row>
                                                <Col>
                                                    <br />
                                                    <h2>Transacción No Encontrada N° <strong>{buyOrder}</strong></h2>
                                                    <p>Las posibles causas son:</p>
                                                    <p>- Error en el ingreso de los datos de su tarjeta de crédito o Debito (fecha y/o código de seguridad).</p>
                                                    <p>- Su tarjeta de crédito o debito no cuenta con el cupo necesario para cancelar la compra.</p>
                                                    <p>-Tarjeta aún no habilitada en el sistema financiero</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <br />
                                                    <Link to={'/'} className="btn btn-verde button btn btn-secondary">Volver</Link>
                                                </Col>
                                            </Row>
                                        </Container>
                                    )
                                )}
                            </>
                        </p>
                    </div>
                </div>
            </>
            
        )
    }


}

export default PagoRapidoKhipuError;